import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

const ImageBackdrop = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#000',
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '40vh',
    [theme.breakpoints.down('md')]: {
        width: '100% !important',
        height: 100,
    },
    '&:hover': {
        zIndex: 1,
    },
    '&:hover .hoverImage': {
        opacity: 1,
    },
    '& .hoverImage': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
        transition: 'opacity 0.3s ease',
        opacity: 0,
    },
    '& .imageTitle': {
        position: 'relative',
        padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
    },
    '&:hover .imageTitle': {
        border: '4px solid currentColor',
    },
    '& .imageMarked': {
        height: 3,
        width: 18,
        background: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    '&:hover .imageMarked': {
        opacity: 0,
    },
}));

/*
https://pixabay.com/cs/photos/rodina-slunce-milovat-prarodi%C4%8De-7392843/
https://pixabay.com/cs/illustrations/ai-generovan%C3%A9-%C5%BEena-ch%C5%AFze-p%C5%99%C3%ADroda-9082395/
https://pixabay.com/cs/illustrations/ai-generovan%C3%A9-%C5%BEena-ch%C5%AFze-p%C5%99%C3%ADroda-9082395/
https://pixabay.com/cs/photos/%C5%BEena-pole-horkovzdu%C5%A1n%C3%A9-bal%C3%B3ny-5628426/
https://pixabay.com/cs/vectors/ko%C4%8Dka-jazz-pop-art-oslava-7515477/
https://pixabay.com/cs/photos/%C5%BEenich-svatba-p%C5%99%C3%A1tel%C3%A9-lid%C3%A9-oslava-8581683/
https://pixabay.com/cs/photos/%C5%BEena-modelka-portr%C3%A9t-p%C3%B3za-styl-3584435/
 */

const images = [
    {
        url: '/static/inspiration/family_moments.jpg',
        url2: '/static/inspiration/family_moments.jpg',
        title: 'Rodinné okamžiky',
        description: 'Zachyťte nezapomenutelné chvíle s vašimi blízkými. Ideální pro rodinné portréty a speciální události.',
        width: '40%',
    },
    {
        url: '/static/inspiration/nature.jpg',
        url2: '/static/inspiration/nature.jpg',
        title: 'Příroda',
        description: 'Zachyťte krásu přírody – od dechberoucích západů slunce po fascinující detaily zvířat a rostlin.',
        width: '20%',
    },
    {
        url: '/static/inspiration/travel.jpg',
        url2: 'static/inspiration/travel.jpg',
        title: 'Cestovatelské zážitky',
        description: 'Proměňte nádherné krajiny a cestovatelské fotografie v trvalé umělecké dílo na kovu.',
        width: '40%',
    },
    {
        url: '/static/inspiration/art.png',
        url2: 'static/inspiration/art.png',
        title: 'Umění',
        description: 'Vyjádřete svůj styl s abstraktními vzory a moderními motivy, které dodají interiéru jedinečný nádech.',
        width: '38%',
    },
    {
        url: '/static/inspiration/life_moments.jpg',
        url2: '/static/inspiration/life_moments.jpg',
        title: 'Životní momenty',
        description: 'Uchovejte si důležité okamžiky – svatby, oslavy, nebo fotografie vašich dětí.',
        width: '38%',
    },
    {
        url: '/static/inspiration/portret.jpg',
        url2: '/static/inspiration/portret.jpg',
        title: 'Portrét',
        description: 'Elegantní portréty pro uchování jedinečných osobností a emocí.',
        width: '24%',
    },
];

export default function ProductCategories() {
    const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

    return (
        <Container component="section" sx={{ mt: 8, mb: 8 }}>
            <Typography variant="h2" marked="center" align="center" component="h1">
                Od vzpomínek ke kovovému umění
            </Typography>
            <Typography variant="h5" marked="center" align="center" sx={{ paddingTop: 5 }}>
                Proměňte své vzpomínky v jedinečné kovové umění!
            </Typography>
            <Box sx={{ mt: 5, display: 'flex', flexWrap: 'wrap' }}>
                {images.map((image, index) => (
                    <ImageIconButton
                        key={image.title}
                        style={{
                            width: image.width,
                        }}
                        onClick={() => setSelectedIndex(selectedIndex === index ? null : index)}
                    >
                        {/* Main Background Image */}
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center 40%',
                                backgroundImage: `url(${image.url})`,
                            }}
                        />
                        {/* Hover Image */}
                        <Box
                            className="hoverImage"
                            sx={{
                                backgroundImage: `url(${image.url2})`,
                            }}
                        />
                        <ImageBackdrop className="imageBackdrop" />
                        <Box
                            sx={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'common.white',
                                textAlign: 'center',
                            }}
                        >
                            {selectedIndex !== index && (
                                <Typography
                                component="h3"
                                variant="h6"
                                color="inherit"
                                className="imageTitle"
                            >
                                {image.title}
                            </Typography>
                            )}
                            {selectedIndex === index && (
                                <Typography
                                    component="p"
                                    variant="body1"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        background: 'rgba(0,0,0,0.7)', // Celý obrázek pokryje poloprůhledné pozadí
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '20px',
                                        textAlign: 'center',
                                        borderRadius: 'inherit', // Zachová zaoblené rohy, pokud je má rodič
                                    }}
                                >
                                    {image.description}
                                </Typography>

                            )}
                        </Box>
                    </ImageIconButton>
                ))}
            </Box>
        </Container>
    );
}
