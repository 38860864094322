import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Product, Size } from '../PlateSizeType';
import { formatSize } from '../../resources/sizesConstants';
import { Link } from 'react-router-dom';

interface ProductProps {
    id: number;
    name: string;
    size: Size; // Velikost obrazu
    description: string;
    price: number;
    product: Product;
    ribbon?: boolean;
    ribbonText?: string;
}

const ProductCard: React.FC<ProductProps> = ({ id, name, size, description, price, product, ribbon, ribbonText }) => {
    const handleScrollToTop = () => {
        window.scrollTo(0, 0); // Posun na začátek stránky
    };

    return (
        <Link
            to={`/product/${product.id}`} // Dynamická URL pro konkrétní produkt
            onClick={handleScrollToTop}
            style={{ textDecoration: 'none', color: 'inherit' }} // Odstranění podtržení odkazu a zachování barev
        >
            <Card
                sx={{
                    maxWidth: 300,
                    margin: 2,
                    padding: 2,
                    border: '1px solid #E0E0E0',
                    borderRadius: '12px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    position: 'relative', // Důležité pro umístění "Doporučujeme"
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                    },
                }}
            >

                {ribbon && ribbonText && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 21, // Jemně posunuto níž
                            left: -28, // Lepší posun pro zarovnání
                            backgroundColor: 'primary.main', // Zelené pozadí
                            color: 'secondary.main', // Barva textu
                            padding: '6px 32px', // Upravený padding pro lepší rovnováhu
                            margin: '-4px', // Negativní okraje pro přesah
                            transform: 'rotate(-35deg)', // Šikmý text
                            fontWeight: 'bold',
                            fontSize: '13px',
                            textAlign: 'center', // Text vycentrován horizontálně
                            zIndex: 1, // Nad kartou
                            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', // Jemný stín pro stuhu
                        }}
                    >
                        Doporučujeme
                    </Box>
                )}



                <CardContent>
                    <Typography
                        variant="h1"
                        sx={{
                            fontSize: 17,
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: 'primary.main',
                            paddingBottom: 4,
                        }}
                    >
                        {product.name}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 2,
                            padding: 1,
                            backgroundColor: '#F5F5F5',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                            }}
                        >
                            Velikost: {formatSize(product.size)}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 2,
                            padding: 1,
                            backgroundColor: '#F5F5F5',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                            }}
                        >
                            Tloušťka: {product.thick} mm
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 2,
                            padding: 1,
                            backgroundColor: '#F5F5F5',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                            }}
                        >
                            Kulaté rohy: {product.roundedCorner ? 'Ano' : 'Ne'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 1,
                            borderRadius: '8px',
                            backgroundColor: 'primary.light',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                color: 'primary.contrastText',
                            }}
                        >
                            {product.price} Kč
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Link>
    );
};

export default ProductCard;
