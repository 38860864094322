import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

interface InfoBarProps {
    message: string;
    backgroundColor?: string; // Barva pozadí (volitelné)
    textColor?: string;       // Barva textu (volitelné)
}

const InfoBar: React.FC<InfoBarProps> = ({ message, backgroundColor, textColor }) => {
    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: backgroundColor || 'secondary.main', // Výchozí barva pozadí
                color: textColor || 'secondary.contrastText',         // Výchozí barva textu
                py: 1,
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    variant="body1"
                    align="center"
                    sx={{
                        fontSize: { xs: '0.875rem', sm: '1rem' }, // Responzivní velikost textu
                    }}
                >
                    {message}
                </Typography>
            </Container>
        </Box>
    );
};

export default InfoBar;
