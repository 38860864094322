import React, {ChangeEvent} from "react";
import Grid from "@mui/material/Grid2";
import {Button, Divider, FormControl, InputLabel, Select, Typography} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useRecoilState, useRecoilValue} from "recoil";
import {cartState, checkoutState} from "../recoil/atoms";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {SelectChangeEvent} from "@mui/material/Select";

const CartItem: React.FC = () => {
    const cartData = useRecoilValue(cartState);
    const [cartItems, setCartItems] = useRecoilState(cartState);
    const [checkoutData, setCheckoutData] = useRecoilState(checkoutState);

    const removeFromCart = (id: number) => {
        setCartItems(cartItems.filter((item) => item.id !== id));
        setCheckoutData((prev) => ({
          ...prev,
          cartItems: cartItems
        }));
    };

    const addQuantity = (id: number) => {
        setCartItems(cartItems.map(item =>
            item.id === id ? { ...item, quantity: item.quantity + 1 } : item
        ));
    };

    const subtractQuantity = (id: number) => {
        setCartItems(cartItems.map(item =>
            item.id === id ? { ...item, quantity: Math.max(item.quantity - 1, 1) } : item
        ));
    };

    const calculateTotalAmount = () => {
        const itemsTotal = cartData.reduce((total, item) => total + (item.mount?.price !== undefined ? item.price + item.mount?.price : item.price) * item.quantity, 0);
        return itemsTotal;
    };


    return (
        <Box sx={{width: '100%'}}>
            {cartData.length < 1 &&(
                <Typography>Váš nákupní košík je prázdný</Typography>
            )}
            {cartData.map((item) => (
                <Grid container key={item.id} spacing={2} pb={2} alignItems="center" sx={{ width: '100%' }}>
                    <Grid size={5}>
                        <Typography variant="h5">
                            {item.name}
                        </Typography>
                        <Typography variant="body2">
                            Velikost: {item.size.height} x {item.size.width} mm
                        </Typography>
                        <Typography variant="body2">
                            Tloušťka: {item.thick} mm
                        </Typography>
                        <Typography variant="body2">
                            Kulaté rohy: {item.roundedCorner ? 'Ano' : 'Ne'}
                        </Typography>
                        <Typography variant="body2">
                            Způsob uchycení: {item.mount?.name}
                        </Typography>
                    </Grid>

                    <Grid size={2} container alignItems="center" justifyContent="center">
                        <Button onClick={() => subtractQuantity(item.id)} sx={{ minWidth: '24px', p: 0.5 }}>
                            <Remove fontSize="small" />
                        </Button>
                        <Typography variant="body1" sx={{ mx: 1 }}>{item.quantity}</Typography>
                        <Button onClick={() => addQuantity(item.id)} sx={{ minWidth: '24px', p: 0.5 }}>
                            <Add fontSize="small" />
                        </Button>
                    </Grid>

                    <Grid size={2} container alignItems="center" justifyContent="center">
                        <Typography variant="body1">{item.mount?.price !== undefined ? item.price + item.mount?.price : item.price} Kč/ks</Typography>
                    </Grid>
                    <Grid size={2} container alignItems="center" justifyContent="center">
                        <Typography variant="body1">{item.mount?.price !== undefined ? (item.price + item.mount?.price) * item.quantity : item.price * item.quantity} Kč</Typography>
                    </Grid>

                    <Grid size={1} container justifyContent="flex-end">
                        <IconButton onClick={() => removeFromCart(item.id)}>
                            <CloseIcon sx={{ color: 'red' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}

            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
                <Typography variant="h6">Celkem za zboží</Typography>
                <Typography variant="h6">{calculateTotalAmount()} Kč</Typography>
            </Box>
        </Box>
    );
}

export default CartItem;