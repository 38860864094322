import React from 'react';
import { Typography, Box, Divider, Stack, IconButton, Container, Link, Grid } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const ContactPage = () => {
    return (
        <Container
            component="section"
            sx={{ display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'center', my: 9 }}
        >
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    width: '100%',
                }}
            >
                <Typography variant="h4" fontWeight="bold" align="center" gutterBottom>
                    Kontakty
                </Typography>

                <Divider sx={{ my: 3 }} />

                <Grid container spacing={3}>
                    {/* Sídlo */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="flex-start" gap={2}>
                            <IconButton color="primary" aria-label="Sídlo">
                                <BusinessIcon fontSize="large" />
                            </IconButton>
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Sídlo
                                </Typography>
                                <Typography>Lindosoft s.r.o.</Typography>
                                <Typography>IČO: 09680195</Typography>
                                <Typography>Čujkovova 1714/21</Typography>
                                <Typography>700 30 Ostrava</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Telefon */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="flex-start" gap={2}>
                            <IconButton color="primary" aria-label="Telefon">
                                <PhoneIcon fontSize="large" />
                            </IconButton>
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Telefon
                                </Typography>
                                <Link href="tel:+420732824728" underline="hover" color="inherit">
                                    +420 732 824 728
                                </Link>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} md={4}>
                        <Box display="flex" alignItems="flex-start" gap={2}>
                            <IconButton color="primary" aria-label="E-mail">
                                <EmailIcon fontSize="large" />
                            </IconButton>
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    E-mail
                                </Typography>
                                <Link href="mailto:info@printking.cz" underline="hover" color="inherit">
                                    info@printking.cz
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ContactPage;
