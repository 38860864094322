import { Area } from "react-easy-crop/types";
import Box from "@mui/material/Box";

interface OutputProps {
    image: string;
    croppedArea: Area;
    cropAreaAspect: number;
}

const Output: React.FC<OutputProps> = ({ image, croppedArea, cropAreaAspect }) => {
    const scale = 100 / croppedArea.width;
    const transform = {
        x: `${-croppedArea.x * scale}%`,
        y: `${-croppedArea.y * scale}%`,
        scale,
        width: "calc(100% + 0.5px)",
        height: "auto"
    };

    const imageStyle: React.CSSProperties = {
        transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
        width: transform.width,
        height: transform.height,
        position: 'absolute' as 'absolute', // Oprava typu pro position
        top: 0,
        left: 0,
        transformOrigin: 'top left' as 'top left' // Oprava typu pro transformOrigin
    };

    return (
        <Box sx={{
            height: '50vh',
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Box
                sx={{
                    position: 'relative',
                    width: cropAreaAspect > 1 ? 'min(90vw, 350px)' : `min(90vw, ${350 * cropAreaAspect}px)`,
                    overflow: 'hidden',
                    boxShadow: '0 0 32px rgba(0, 0, 0, 0.3)',
                    paddingBottom: cropAreaAspect > 1 ? `${100 / cropAreaAspect}%` : 'min(90vw, 350px)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px'
                }}
            >
                <img
                    src={image}
                    alt=""
                    style={imageStyle}
                />
            </Box>
        </Box>

    );
};

export default Output;
