// sizes.ts

import {MountType, Product} from "../components/PlateSizeType";

const mountTypeSmallSheet: Array<MountType> = [
    {id: 1, name: "Dřevěný stojánek", price: 20, description: "Pro umístění na stůl."}
]

const mountType: Array<MountType> = [
    {id: 1, name: "Dřevěný stojánek", price: 20, description: "Pro umístění na stůl."},
    {id: 2, name: "\"Neviditelný\" rámeček", price: 0, description: "Pro umístění na stěnu."}
]

export const products: Array<Product> = [
    { id: 1, name: "Kovový obraz", size: {width: 152, height: 203}, thick: 0.75, roundedCorner: true,  price: 299, mountType: mountType },
    { id: 2, name: "Kovový obraz", size: {width: 152, height: 203}, thick: 1.0, roundedCorner: true,  price: 309, mountType: mountType },
    { id: 3, name: "Kovový obraz", size: {width: 152, height: 203}, thick: 1.15, roundedCorner: true,  price: 349, recommended: true, mountType: mountType },
    { id: 4, name: "Kovový obraz", size: {width: 178, height: 254}, thick: 0.75, roundedCorner: true,  price: 399, mountType: mountType },
    { id: 5, name: "Kovový obraz", size: {width: 178, height: 254}, thick: 1.0, roundedCorner: true,  price: 409, mountType: mountType },
    { id: 6, name: "Kovový obraz", size: {width: 178, height: 254}, thick: 1.15, roundedCorner: true,  price: 449, recommended: true, mountType: mountType },
    { id: 7, name: "Kovový obraz", size: {width: 200, height: 285}, thick: 1.0, roundedCorner: false,  price: 499, mountType: mountType },
    { id: 8, name: "Kovový obraz", size: {width: 200, height: 285}, thick: 1.0, roundedCorner: true, price: 499, mountType: mountType }
];

export const formatSize = (size: { width: number, height: number }) => `${size.width} x ${size.height} mm`;