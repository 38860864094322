import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import {CheckoutState} from "../recoil/atoms";
import {mapCheckoutStateToOrder} from "./mappers/checkoutUtils";
import {API_SERVER_URL} from "../resources/constants";


const createOrder = async (orderData: CheckoutState): Promise<any> => {
  const mappedData = mapCheckoutStateToOrder(orderData);
  const response = await axios.post(API_SERVER_URL+'/api/v1/orders', mappedData);
  return response.data;
};

export interface ImageRequest {
  id: string;
  base64Image: string;
}

const uploadImage = async (imageData: ImageRequest): Promise<any> => {
  const response = await axios.post(API_SERVER_URL+'/images/upload', imageData);
  return response.data;
};


export const useCreateOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOrder,
    onError: (error: Error) => {
      console.error("Chyba při odesílání objednávky:", error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      console.log("Úspěch:", data);
    }
  });
};

export const useUploadImage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: uploadImage,
    onError: (error: Error) => {
      console.error('Chyba při nahrávání obrázku:', error);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['images'] });
      console.log('Úspěch:', data);
    },
  });
};