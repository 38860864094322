import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import FAQ from "../../../components/FAQ";

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{ alignItems: 'center', my: 9 }}
    >
        <FAQ />
    </Container>
  );
}

export default ProductSmokingHero;
