import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {
    formatSize, products
} from '../resources/sizesConstants';
import {Product} from "./PlateSizeType";

interface PlateSizeProps {
    onChange: (size: Product | null) => void;
}

export default function PlateSize({onChange}: PlateSizeProps) {
    const [size, setSize] = React.useState<Product | null>(null);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        const newSize = value ? JSON.parse(value) : null;
        setSize(newSize);
        console.log("PlateSize", newSize);
        onChange(newSize);
    };

    const formatValue = (size: Product | null) => {
        return size ? JSON.stringify(size) : '';
    };

    return (
        <FormControl sx={{minWidth: 180}} fullWidth size={"small"}>
            <InputLabel id="plate-size-select-label">Velikost obrazu</InputLabel>
            <Select
                labelId="plate-size-select-label"
                id="plate-size-select"
                value={formatValue(size)}
                onChange={handleChange}
                fullWidth
                label="Velikost obrazu"
            >
                {/*<MenuItem value="">*/}
                {/*    <em>nevybráno</em>*/}
                {/*</MenuItem>*/}
                {products.map((product) => {
                    return <MenuItem value={formatValue(product)}>{formatSize(product.size)}</MenuItem>;
                })}
            </Select>
        </FormControl>
    );
}