import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { cartState } from '../recoil/atoms';
import { Typography, Button } from '@mui/material';
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid2';
import {Add, Close, Remove} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckoutOptions from "./CheckoutOptions";
import CheckoutProcess from "./CheckoutProcess";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

const Cart: React.FC = () => {
  const navigate = useNavigate();
  const cart = useRecoilValue(cartState);
  const [cartItems, setCartItems] = useRecoilState(cartState);

  const removeFromCart = (id: number) => {
    setCartItems(cartItems.filter((item) => item.id !== id));
  };

  const addQuantity = (id: number) => {
    setCartItems(cartItems.map(item =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    ));
  };

  const subtractQuantity = (id: number) => {
    setCartItems(cartItems.map(item =>
        item.id === id ? { ...item, quantity: Math.max(item.quantity - 1, 1) } : item
    ));
  };

  return (
      <Container
          component="section"
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
      >
        <Typography variant="h4" pb={5}>Nákupní košík</Typography>
        {cart.map((item) => (
            <Grid container key={item.id} spacing={2} pb={2} alignItems="center" sx={{ width: '100%' }}>
              <Grid size={5}>
                <Typography variant="h5">
                  {item.name}
                </Typography>
                <Typography variant="body2">
                  Velikost: {item.size.height} x {item.size.width} mm
                </Typography>
              </Grid>

              <Grid size={2} container alignItems="center" justifyContent="center">
                <Button onClick={() => subtractQuantity(item.id)} sx={{ minWidth: '24px', p: 0.5 }}>
                  <Remove fontSize="small" />
                </Button>
                <Typography variant="body1" sx={{ mx: 1 }}>{item.quantity}</Typography>
                <Button onClick={() => addQuantity(item.id)} sx={{ minWidth: '24px', p: 0.5 }}>
                  <Add fontSize="small" />
                </Button>
              </Grid>

              <Grid size={2} container alignItems="center" justifyContent="center">
                <Typography variant="body1">{item.price} Kč/ks</Typography>
              </Grid>
              <Grid size={2} container alignItems="center" justifyContent="center">
                <Typography variant="body1">{item.price * item.quantity} Kč</Typography>
              </Grid>

              <Grid size={1} container justifyContent="flex-end">
                <IconButton onClick={() => removeFromCart(item.id)}>
                  <CloseIcon sx={{ color: 'red' }} />
                </IconButton>
              </Grid>
            </Grid>
        ))}
        <Box display="flex" justifyContent="flex-end" width="100%" mt={4}>
          <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/checkout')}
          >
            Pokračovat v objednávce
          </Button>
        </Box>
      </Container>
  );
};

export default Cart;