import React, { useState } from 'react';
import { Box, Typography, TextField, MenuItem, Select, InputAdornment, InputLabel } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SelectChangeEvent } from "@mui/material/Select";
import Grid from '@mui/material/Grid2';
import { useRecoilState } from "recoil";
import { checkoutState } from "../recoil/atoms";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';



const CustomerForm: React.FC = () => {
    const [checkoutData, setCheckoutData] = useRecoilState(checkoutState);
    const [countryCode, setCountryCode] = useState('+420');
    const [emailError, setEmailError] = useState(false);

    const handleCountryCodeChange = (event: SelectChangeEvent) => {
        setCountryCode(event.target.value as string);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCheckoutData(prevState => ({
            ...prevState,
            customerInfo: {
                ...prevState.customerInfo,
                [name]: value
            }
        }));
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCheckoutData(prevState => ({
            ...prevState,
            customerInfo: {
                ...prevState.customerInfo,
                address: {
                    ...prevState.customerInfo.address,
                    [name]: value
                }
            }
        }));
    };

    const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCheckoutData(prevState => ({
            ...prevState,
            message: value
        }));
    };

    const handleStateChange = (event: SelectChangeEvent) => {
        setCheckoutData(prevState => ({
            ...prevState,
            customerInfo: {
                ...prevState.customerInfo,
                address: {
                    ...prevState.customerInfo.address,
                    state: event.target.value
                }
            }
        }));
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(!emailRegex.test(email));
    };

    const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        validateEmail(event.target.value);
    };

    return (
        <Box>
            {/* Osobní údaje */}
            <Box mb={4}>
                <Box display="flex" alignItems="center" mb={2}>
                    <PersonIcon color="primary" />
                    <Typography variant="h6" ml={1}>Osobní údaje</Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid size={6}>
                        <TextField
                            label="Jméno"
                            name="firstName"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.firstName || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            label="Příjmení"
                            name="lastName"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.lastName || ""}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            label="Telefon"
                            name="phone"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.phone || ""}
                            onChange={handleInputChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Select
                                            value={countryCode}
                                            onChange={handleCountryCodeChange}
                                            variant="standard"
                                            disableUnderline
                                            sx={{
                                                minWidth: 70,
                                                '& .MuiSelect-select': {
                                                    padding: 0,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                },
                                            }}
                                        >
                                            <MenuItem value="+420">
                                                <img src="https://flagcdn.com/w20/cz.png" alt="CZ" style={{ marginRight: 8 }} />
                                                +420
                                            </MenuItem>
                                            <MenuItem value="+421">
                                                <img src="https://flagcdn.com/w20/sk.png" alt="SK" style={{ marginRight: 8 }} />
                                                +421
                                            </MenuItem>
                                        </Select>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            label="E-mail"
                            name="email"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.email || ""}
                            onChange={handleInputChange}
                            onBlur={handleEmailBlur}
                            error={emailError}
                            helperText={emailError ? "Neplatný e-mail" : ""}
                        />
                    </Grid>
                </Grid>
            </Box>

            {/* Fakturační adresa */}
            <Box mb={4}>
                <Box display="flex" alignItems="center" mb={2}>
                    <LocationOnIcon color="primary" />
                    <Typography variant="h6" ml={1}>Adresa</Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <TextField
                            label="Ulice a číslo popisné"
                            name="street"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.address.street || ""}
                            onChange={handleAddressChange}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            label="Město"
                            name="city"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.address.city || ""}
                            onChange={handleAddressChange}
                        />
                    </Grid>
                    <Grid size={6}>
                        <TextField
                            label="PSČ"
                            name="zipCode"
                            required
                            fullWidth
                            value={checkoutData.customerInfo.address.zipCode || ""}
                            onChange={handleAddressChange}
                        />
                    </Grid>
                    <Grid size={12}>
                        <InputLabel>Země*</InputLabel>
                        <Select
                            fullWidth
                            required
                            value={checkoutData.customerInfo.address.state}
                            onChange={handleStateChange}
                        >
                            <MenuItem value="Česká republika">Česká republika</MenuItem>
                            <MenuItem value="Slovenská republika">Slovenská republika</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Box>

            {/* Poznámka k objednávce */}
            <Box mb={4}>
                <Box display="flex" alignItems="center" mb={2}>
                    <StickyNote2Icon color="primary" />
                    <Typography variant="h6" ml={1}>Poznámka k objednávce</Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <TextField
                            label="Vaše poznámka"
                            name="note"
                            fullWidth
                            value={checkoutData.message|| ""}
                            onChange={handleChangeNote}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CustomerForm;