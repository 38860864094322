import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import UploadIcon from '@mui/icons-material/Upload';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ImageCropper from "../../../components/ImageCropper/ImageCropper";

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'primary.main',
  fontWeight: 'medium',
};

const image = {
  fontSize: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
      <Box
          component="section"
          id={"order"}
          sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
      >
        <Container
            sx={{
              mt: 5,
              mb: 5,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
        >
          <Box
              component="img"
              src="/static/productCurvyLines.png"
              alt="curvy lines"
              sx={{
                pointerEvents: 'none',
                position: 'absolute',
                top: -180,
                opacity: 0.7
              }}
          />
          <Typography variant="h4" marked="center" component="h2" sx={{ mb: 6 }}>
            Vytvořit obraz
          </Typography>
          <div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box sx={number}>1.</Box>
                  <Box><UploadIcon sx={image} /></Box>
                  <Typography variant="h5" align="center">
                    Nahrajte svou fotku.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box sx={number}>2.</Box>
                  <Box><DesignServicesIcon sx={image} /></Box>
                  <Typography variant="h5" align="center">
                    Přizpůsobte svůj obraz
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box sx={number}>3.</Box>
                  <Box><AddShoppingCartIcon sx={image} /></Box>
                  <Typography variant="h5" align="center">
                    Objednejte a těšte se na doručení
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
          {/*<Button*/}
          {/*    color="secondary"*/}
          {/*    size="large"*/}
          {/*    variant="contained"*/}
          {/*    component="a"*/}
          {/*    href="/premium-themes/onepirate/sign-up/"*/}
          {/*    sx={{ mt: 8 }}*/}
          {/*>*/}
          {/*  Nahrát obrázek*/}
          {/*</Button>*/}
          <Box sx={{ mt: 1, width: '100%' }}>
            <ImageCropper />
          </Box>
        </Container>
      </Box>
  );
}

export default ProductHowItWorks;