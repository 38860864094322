import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface InsertToCartModalProps {
    open: boolean;
    onClose: () => void;
    onCreateAnother: () => void;
    onViewCart: () => void;
}

const InsertToCartDialog: React.FC<InsertToCartModalProps> = ({
                                                                  open,
                                                                  onClose,
                                                                  onCreateAnother,
                                                                  onViewCart,
                                                              }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Položka byla přidána do košíku</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Váš obraz byl úspěšně přidán do košíku. Můžete pokračovat ve vytváření dalších obrazů nebo zobrazit obsah vašeho košíku.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={onCreateAnother}
                >
                    Vytvořit další obraz
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<ShoppingCartIcon />}
                    onClick={onViewCart}
                >
                    Zobrazit košík
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InsertToCartDialog;