import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';
import {csCZ as corecsCZ} from '@mui/material/locale';
/*
 TODO upravit theme, imlementovat theme provider do App namisto MainApp
 */
const theme = createTheme({
        palette: {
            primary: {
                main: '#f0c031',
                dark: '#bd9112'
            },
            secondary: {
                main: '#1E1E1E',
                light: '#424242',
            },
            error: {
                main: red.A400,
            },
            // background: {
            //     paper: '#fff',
            // }
        },
        typography: {
            fontFamily: ['sans-serif'].join(','),
            body2: {
                fontSize: '1rem',
                lineHeight: 1.7
            }
            // fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none', // Prevent button text from being uppercase
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#ffffff', // Set the background color for text fields
                    },
                },
            },
        },
        shape: {
            borderRadius: 0,
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1720,
            },
        },
    },
    corecsCZ, // core translations
);

export default theme;
