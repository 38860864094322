import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <title>PrintKing.cz - Luxusní kovové obrazy</title>
                <link rel="canonical" href="https://www.printking.cz"/>
                <meta name="description"
                      content="Proměňte své vzácné vzpomínky v jedinečné kovové umění a vdechněte svému interiéru eleganci. Kovové obrazy se pyšní luxusním designem a mimořádnou trvanlivostí."/>
                <meta name="keywords" content="tisk na hliníkové plechy, kovové obrazy, luxusní obrazy, metalické fotografie, vlastní design, kvalitní tisk"/>
                <meta property="og:title" content="PrintKing.cz - Kovové obrazy na míru"/>
            </Helmet>
            <App/>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
