import React from 'react';
import { Box, Typography, Divider, Paper } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { checkoutState, cartState } from '../recoil/atoms';
import Grid from '@mui/material/Grid2';

const OrderSummary: React.FC = () => {
    const checkoutData = useRecoilValue(checkoutState);
    const cartData = useRecoilValue(cartState);

    const calculateTotalAmount = () => {
        const itemsTotal = cartData.reduce((total, item) => total + item.price * item.quantity, 0);
        const shippingCost = checkoutData.shippingPaymentInfo.shippingMethod?.price || 0;
        const paymentCost = checkoutData.shippingPaymentInfo.paymentMethod?.price || 0;
        return itemsTotal + shippingCost + paymentCost;
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 800, margin: 'auto' }}>
            <Box mb={4}>
                <Typography variant="h5" gutterBottom align="center">Rekapitulace objednávky</Typography>
                <Divider />
            </Box>

            {/* Osobní údaje */}
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>Osobní údaje</Typography>
                <Grid container spacing={2}>
                    <Grid size={6}>
                        <Typography variant="body1"><strong>Jméno:</strong> {checkoutData.customerInfo.firstName}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1"><strong>Příjmení:</strong> {checkoutData.customerInfo.lastName}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1"><strong>Telefon:</strong> {checkoutData.customerInfo.phone}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1"><strong>E-mail:</strong> {checkoutData.customerInfo.email}</Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* Fakturační adresa */}
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>Fakturační adresa</Typography>
                <Grid container spacing={2}>
                    <Grid size={12}>
                        <Typography variant="body1"><strong>Ulice a číslo popisné:</strong> {checkoutData.customerInfo.address.street}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1"><strong>Město:</strong> {checkoutData.customerInfo.address.city}</Typography>
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="body1"><strong>PSČ:</strong> {checkoutData.customerInfo.address.zipCode}</Typography>
                    </Grid>
                    <Grid size={12}>
                        <Typography variant="body1"><strong>Země:</strong> {checkoutData.customerInfo.address.state}</Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* Poznámka */}
            {checkoutData.message && (
                <Box mb={4}>
                    <Typography variant="h6" gutterBottom>Poznámka k objednávce</Typography>
                    <Grid container spacing={2}>
                        <Grid size={12}>
                            <Typography variant="body1">{checkoutData.message}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}


            {/* Obsah objednávky */}
            <Box mb={4}>
                <Typography variant="h6" gutterBottom>Obsah objednávky</Typography>
                <Divider sx={{ mb: 2 }} />

                {cartData.length > 0 ? (
                    <Box>
                        {/* Hlavička tabulky */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', mb: 1 }}>
                            <Box sx={{ flex: 2 }}>
                                <Typography variant="body1">Produkt</Typography>
                            </Box>
                            <Box sx={{ flex: 1, textAlign: 'center' }}>
                                <Typography variant="body1">Množství</Typography>
                            </Box>
                            <Box sx={{ flex: 1, textAlign: 'right' }}>
                                <Typography variant="body1">Cena za kus</Typography>
                            </Box>
                            <Box sx={{ flex: 1, textAlign: 'right' }}>
                                <Typography variant="body1">Cena celkem</Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ mb: 2 }} />

                        {/* Položky v košíku */}
                        {cartData.map((item, index) => (
                            <Box key={index} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ flex: 2 }}>
                                    <Typography variant="body1">{item.name} {item.size.height} x {item.size.width} mm</Typography>
                                    <Typography variant="body2">Tloušťka: {item.thick} mm, kulaté rohy: {item.roundedCorner ? 'Ano' : 'Ne'}</Typography>
                                    <Typography variant="body2">Způsob uchycení: {item.mount?.name}</Typography>
                                </Box>
                                <Box sx={{ flex: 1, textAlign: 'center' }}>
                                    <Typography variant="body1">{item.quantity}</Typography>
                                </Box>
                                <Box sx={{ flex: 1, textAlign: 'right' }}>
                                    <Typography variant="body1">{`${item.price} Kč`}</Typography>
                                </Box>
                                <Box sx={{ flex: 1, textAlign: 'right' }}>
                                    <Typography variant="body1">{`${item.price * item.quantity} Kč`}</Typography>
                                </Box>
                            </Box>
                        ))}
                        <Divider sx={{ my: 2 }} />

                        {/* Doprava a platba */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body1"><strong>Doprava:</strong></Typography>
                            <Typography variant="body1">{checkoutData.shippingPaymentInfo.shippingMethod?.name} - {checkoutData.shippingPaymentInfo.shippingMethod?.price} Kč</Typography>
                        </Box>
                        {/* Data o zasilkovne*/}
                        {checkoutData.shippingPaymentInfo.shippingMethod?.place &&
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="body1"></Typography>
                            <Typography variant="body2">{checkoutData.shippingPaymentInfo.shippingMethod?.placeText}</Typography>
                        </Box>
                        }
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="body1"><strong>Platba:</strong></Typography>
                            <Typography variant="body1">{checkoutData.shippingPaymentInfo.paymentMethod?.name} - {checkoutData.shippingPaymentInfo.paymentMethod?.price} Kč</Typography>
                        </Box>

                        {/* Celková částka */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <Typography variant="h6"><strong>Celková částka:</strong></Typography>
                            <Typography variant="h6"><strong>{calculateTotalAmount()} Kč</strong></Typography>
                        </Box>
                    </Box>
                ) : (
                    <Typography variant="body1">Košík je prázdný</Typography>
                )}
            </Box>


        </Paper>
    );
};

export default OrderSummary;
