import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from "./components/NavBar";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AppRouter from "./router/AppRouter";
import {ThemeProvider} from "@mui/material";
import theme from "./theme/theme";
import {RecoilRoot} from "recoil";
import ToastProvider from "./components/Toast/ToastProvider";


function App() {
    const queryClient = new QueryClient()
    return (
        <BrowserRouter basename="/">
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <RecoilRoot>
                        <ToastProvider>
                            <AppRouter />
                        </ToastProvider>
                    </RecoilRoot>
                </ThemeProvider>
                {/*<ReactQueryDevtools initialIsOpen={false} />*/}
            </QueryClientProvider>
        </BrowserRouter>

    );
}

export default App;
