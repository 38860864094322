import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';

interface PacketaOptions {
  country: string;
  language: string;
  valueFormat: string;
  view: string;
  vendors: Array<{ country: string; group?: string }>;
}

export interface PacketaWidgetRef {
  handlePickButtonClick: () => void;
  resetStates: () => void;
}

interface PickupPoint {
  formatedValue: string;
  name: string;
  place: string;
}

interface PacketaWidgetProps {
  onPointSelected: (point: string, text:string) => void;
}

const PacketaWidget: React.ForwardRefRenderFunction<PacketaWidgetRef, PacketaWidgetProps> = ({ onPointSelected }, ref) => {
  const [selectedPoint, setSelectedPoint] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [place, setPlace] = useState<string>('');
  const packetaApiKey = 'XXX XXX XXX';

  const packetaOptions: PacketaOptions = {
    country: 'cz,sk',
    language: 'cs',
    valueFormat: '"Packeta",id,carrierId,carrierPickupPointId,name,city,street',
    view: 'modal',
    vendors: [
      { country: 'cz', group: 'zbox' },
      { country: 'cz' },
      { country: 'sk' },
      { country: 'sk', group: 'zbox' },
    ],
  };

  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (!scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = 'https://widget.packeta.com/v6/www/js/library.js';
      script.async = true;
      document.body.appendChild(script);
      scriptLoaded.current = true;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handlePickButtonClick,
    resetStates,
  }));

  const handlePickButtonClick = () => {
    (window as any).Packeta?.Widget.pick(
        packetaApiKey,
        (point: PickupPoint | null) => {
          if (point) {
            const formattedPoint = `Adresa: ${point.formatedValue}`;
            setSelectedPoint(formattedPoint);
            setName(point.name);
            setPlace(point.place);
            const text = `${point.name !== 'Z-BOX' ? `${point.name} ` : ''}${point.place !== 'Z-BOX' ? ` - ${point.place}` : ''}`;
            onPointSelected(formattedPoint, text); // Předání vybraného bodu rodiči
            console.log('Selected point', point, text);
          }
        },
        packetaOptions
    );
  };

  const resetStates = () => {
    console.log("reset states packeta widget")
    setSelectedPoint('');
    setName('');
    setPlace('');
  };

  return (
      <div>
        {selectedPoint ? (
            <div className="packeta-selector-value">
              {/*{name !== 'Z-BOX' && `${name} `}*/}
              {/*{place !== 'Z-BOX' && ` - ${place}`}*/}
            </div>
        ) : (
            <button className="packeta-selector-open" onClick={handlePickButtonClick}>
              Zvolte odběrné místo
            </button>
        )}
      </div>
  );
};

export default forwardRef(PacketaWidget);