export const sendGtagEvent = (event: string, eventname: string, params: Record<string, any>) => {
    if (typeof window.gtag === 'function') {
        console.log("odesilam event");
        window.gtag(event, eventname, params);
    } else {
        console.warn('gtag is not defined. Event not sent:', event, params);
    }
};



export const sendToDatalayer = (event: string, params?: Record<string, any>) => {
    if (Array.isArray(window.dataLayer)) {
        console.log("Sending to dataLayer");
        window.dataLayer.push({
            event: event,
            ...params,
        });
    } else {
        console.warn('dataLayer is not defined. Event not sent:', event, params);
    }
};