import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2'; // Import Grid verze 2
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Kontakt from "../../ContactBox";

function Copyright() {
  return (
      <React.Fragment>
        <Typography color="primary">
          {'© '}
          <Link color="inherit" href="https://www.printking.cz">
            PrintKing.cz
          </Link>{' '}
          {new Date().getFullYear()}
        </Typography>
      </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'warning.main',
  mr: 1,
  '&:hover': {
    bgcolor: 'warning.dark',
  },
};

export default function AppFooter() {
  return (
      <Box
          component="footer"
          sx={{
            display: 'flex',
            position: 'relative',
            overflow: 'hidden',
            bgcolor: 'secondary.main',
            backgroundImage: 'url(/static/footer.jpg)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Poloprůhledný overlay
              zIndex: 0,
            },
          }}
      >
        <Container sx={{ my: 8, position: 'relative', zIndex: 1 }}>
          <Grid
              container
              spacing={5}
              justifyContent="space-evenly"
          >
            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
              <Copyright />
            </Grid>

            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
              <Typography variant="h6" marked="left" color="primary" gutterBottom>
                Informace
              </Typography>
              <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                <Box component="li" sx={{ py: 0.5 }}>
                  {/*<Link href="/terms/" color={"secondary.light"}>Obchodní podmínky</Link>*/}
                  <Link href="/files/Obchodní%20podmínky.pdf" color={"secondary.light"} target={"_blank;"}>Obchodní podmínky</Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  {/*<Link href="/privacy/" color={"secondary.light"}>Zpracování osobních údajů</Link>*/}
                    <Link href="/files/Podmínky%20ochrany%20osobních%20údajů.pdf" color={"secondary.light"} target={"_blank;"}>Zpracování osobních údajů</Link>

                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                  {/*<Link href="/privacy/" color={"secondary.light"}>Zpracování osobních údajů</Link>*/}
                    <Link href="/files/Doprava%20a%20platba.pdf" color={"secondary.light"} target={"_blank;"}>Doprava a platba</Link>

                </Box>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
              <Typography variant="h6" marked="left" color="primary" gutterBottom>
                Provozovatel
              </Typography>
              <Kontakt
                  nazevFirmy="Lindosoft s.r.o."
                  ico={"09680195"}
                  adresa="Čujkovova 1714/21, Ostrava, 700 30"
                  telefon="+420 732 824 728"
                  email="info@printking.cz"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
  );
}