import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import HdrEnhancedSelectIcon from '@mui/icons-material/HdrEnhancedSelect';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DiamondIcon from '@mui/icons-material/Diamond';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Container sx={{ mt: 7, mb: 10, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src="/static/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/*<Box*/}
              {/*  component="img"*/}
              {/*  src="/static/productValues1.svg"*/}
              {/*  alt="suitcase"*/}
              {/*  sx={{ height: 55 }}*/}
              {/*/>*/}
              <Box><DiamondIcon  sx={{ fontSize: 55 }} /></Box>
              <Typography variant="h6" sx={{ my: 5, textAlign: "center" }}>
                Prémiová kvalita tisku
              </Typography>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Od jemných detailů po syté barvy – naše technologie zajišťuje dokonalou reprodukci každého snímku na odolném hliníkovém plechu.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/*<Box*/}
              {/*  component="img"*/}
              {/*  src="/static/productValues2.svg"*/}
              {/*  alt="graph"*/}
              {/*  sx={{ height: 55 }}*/}
              {/*/>*/}

              <Box><PhotoFilterIcon sx={{ fontSize: 55 }} /></Box>
              <Typography variant="h6" sx={{ my: 5, textAlign: "center" }}>
                Personalizovaný design
              </Typography>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Vytvořte si své vlastní mistrovské dílo! Nahrajte svou fotografii, upravte rozměry podle svých představ v našem jednoduchém konfigurátoru.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              {/*<Box*/}
              {/*  component="img"*/}
              {/*  src="/static/productValues3.svg"*/}
              {/*  alt="clock"*/}
              {/*  sx={{ height: 55 }}*/}
              {/*/>*/}
              <LocalShippingIcon sx={{ fontSize: 55 }} />
              <Typography variant="h6" sx={{ my: 5, textAlign: "center" }}>
                Rychlé doručení
              </Typography>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Vaše kovové obrazy doručíme přímo k vám domů v bezpečném balení, které zajistí, že váš výtvor dorazí v perfektním stavu.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
