import React from 'react';
import { Box, Typography } from '@mui/material';

interface KontaktProps {
  nazevFirmy: string;
  ico: string;
  adresa: string;
  telefon: string;
  email: string;
}

const Kontakt: React.FC<KontaktProps> = ({ nazevFirmy, ico, adresa, telefon, email }) => {
  return (
    <Box
      sx={{
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        maxWidth: '400px',
        margin: '20px auto',
      }}
    >
      <Typography variant="h6" color={"#fff"}>{nazevFirmy}</Typography>
      <Typography color={"#fff"}>IČO: {ico}</Typography>
      <Typography color={"#fff"}>{adresa}</Typography>
      <Typography color={"#fff"}>{telefon}</Typography>
      <Typography color={"#fff"}>{email}</Typography>
    </Box>
  );
};

export default Kontakt;