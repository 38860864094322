import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';

const sizes = [
    {
        size: '152 × 203 mm',
        description: 'Obraz o těchto rozměrech je dostupný ve třech tloušťkách: 0,75 mm, 1 mm nebo 1,15 mm. Základní tloušťka 0,75 mm je ideální volbou pro něžný a elegantní vzhled. Pokud však hledáte obraz s výraznějším a prémiovějším vzhledem, doporučujeme tloušťky 1 mm nebo 1,15 mm, které působí robustněji, a ještě více umocní dojem luxusu.',
    },
    {
        size: '178 × 254 mm',
        description: 'I tato velikost je dostupná ve třech tloušťkách: 0,75 mm, 1 mm nebo 1,15 mm.',
    },
    {
        size: '200 × 285 mm',
        description: 'Nabízíme ji v tloušťce 1 mm. U tohoto formátu si navíc můžete zvolit mezi kulatými nebo hranatými rohy, aby váš obraz perfektně zapadl do každého prostoru.',
    },
];

const TechnicalDetails: React.FC = () => {
    return (
        <React.Fragment>
            <Typography
                variant="body2"
                color="text.secondary"
                paragraph
                sx={{ marginBottom: 3, fontSize: '1rem', lineHeight: 1.7 }}
            >
                Naše obrazy jsou zhotoveny na míru z prémiového hliníkového plechu, který se vyznačuje vysokou pevností a dlouhou životností. Díky tomuto materiálu je zajištěna nejen stabilita, ale i profesionální vzhled. Unikátní povrchová úprava obrazu zajišťuje intenzivní lesk a věrnost barev, čímž garantuje nejen působivý vizuální efekt, ale také výjimečnou odolnost vůči vnějším vlivům.
            </Typography>
            <Typography
                variant="body2"
                color="text.secondary"
                paragraph
                sx={{ marginBottom: 3, fontSize: '1rem', lineHeight: 1.7 }}
            >
                Abychom mohli vyhovět vašim preferencím, nabízíme obrazy ve třech různých velikostech a odlišných tloušťkách. Všechny varianty jsou pevné a stabilní – nemusíte se obávat ohýbání nebo deformací, a to u žádné z dostupných tlouštěk.
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{
                    justifyContent: 'center',
                }}
            >
                {sizes.map((item, index) => (
                    <Grid
                        key={index}
                        size={{ xs: 12, md: 4 }} // Responzivní rozložení (1 sloupec na malých obrazovkách, 3 sloupce na středních a větších)
                    >
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: '#f5f5f5', // Šedé pozadí
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 3,
                                borderRadius: '8px',
                            }}
                        >
                            {/* Velikost nahoře na řádku */}
                            <Box
                                sx={{
                                    borderBottom: '1px solid #ddd', // Oddělení velikosti a popisu
                                    paddingBottom: 1,
                                    marginBottom: 2,
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    color="text.primary"
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: '#333',
                                        textAlign: 'center', // Zarovnání na střed
                                    }}
                                >
                                    {item.size}
                                </Typography>
                            </Box>
                            {/* Popis pod velikostí */}
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    textAlign: 'justify', // Zarovnání popisu do bloku
                                    lineHeight: 1.6,
                                }}
                            >
                                {item.description}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default TechnicalDetails;
