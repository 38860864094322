import React from 'react';
import { Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Import Grid2
import ProductCard from './ProductCard';
import { formatSize, products } from '../../resources/sizesConstants';
import {Helmet} from "react-helmet-async";

const ProductPage: React.FC = () => {
    return (
        <Container sx={{ paddingTop: 10, paddingBottom: 20 }}>
            <Helmet>
                <title>Produkty - Kovové obrazy od PrintKing.cz</title>
                <link rel="canonical" href="https://www.printking.cz/products"/>
                <meta
                    name="description"
                    content="Objevte širokou nabídku kovových obrazů na míru od PrintKing.cz. Luxusní design, dlouhá životnost a jedinečná kvalita. Vyberte si ideální obraz pro váš interiér."
                />
                <meta
                    name="keywords"
                    content="kovové obrazy, hliníkové obrazy, luxusní obrazy, metalické fotografie, dekorace interiéru, moderní obrazy, PrintKing.cz"
                />
                <meta property="og:title" content="Produkty - Kovové obrazy od PrintKing.cz"/>
                <meta
                    property="og:description"
                    content="Vyberte si z nabídky kovových obrazů na míru. Nabízíme široký výběr velikostí a designů, které se hodí do každého interiéru."
                />
                <meta property="og:url" content="https://www.printking.cz/products"/>
                {/*<meta property="og:image" content="https://www.printking.cz/images/products-preview.jpg" />*/}
                <meta property="og:type" content="website"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="Produkty - Kovové obrazy od PrintKing.cz"/>
                <meta
                    name="twitter:description"
                    content="Objevte kovové obrazy na míru od PrintKing.cz. Jedinečný lesk, dlouhá trvanlivost a luxusní design."
                />
                {/*<meta name="twitter:image" content="https://www.printking.cz/images/products-preview.jpg" />*/}

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Produkty - Kovové obrazy od PrintKing.cz",
                        "url": "https://www.printking.cz/products",
                        "description":
                            "Objevte širokou nabídku kovových obrazů na míru od PrintKing.cz. Luxusní design, dlouhá životnost a jedinečná kvalita. Vyberte si ideální obraz pro váš interiér.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "PrintKing.cz",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "%PUBLIC_URL%/logo192.png"
                            }
                        },
                        "breadcrumb": {
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "Domů",
                                    "item": "https://www.printking.cz/"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "Produkty",
                                    "item": "https://www.printking.cz/products"
                                },
                                {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": "Kontakt",
                                    "item": "https://www.printking.cz/contact"
                                }
                            ]
                        }
                    })}
                </script>
            </Helmet>
            <Typography variant="h3" component="h1" gutterBottom align="center">
                Velikosti obrazu
            </Typography>
            <Grid container spacing={2} justifyContent="flex-start" pt={7}>
                {products.map((product) => (
                    <Grid
                        key={product.id}
                        size={{xs: 12, sm: 6, md: 4}} // Nastavení velikostí pro jednotlivé breakpointy
                    >
                        <ProductCard
                            id={product.id}
                            name={product.name}
                            size={product.size}
                            description="TEST"
                            price={product.price}
                            product={product}
                            ribbon={product.recommended}
                            ribbonText={product.recommended ? "Doporučujeme" : ""}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ProductPage;
