import React, {useRef, useState} from 'react';
import { Container, Typography, Radio, RadioGroup, FormControlLabel, Grid, Box, Paper } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Button from '@mui/material/Button';
import { useRecoilState } from "recoil";
import { checkoutState } from "../recoil/atoms";
import PacketaWidget, { PacketaWidgetRef } from "./PacketaWidget";
import {ShippingMethod} from "../types/CartItem";

const shippingOptions:Array<ShippingMethod> = [
    { id: 4, name: "Zásilkovna - Výdejní místo", price: 0 },
    { id: 5, name: "Zásilkovna - Balík na adresu", price: 99 },
    { id: 6, name: "Česká pošta - Balík do ruky", price: 129 },
];

const paymentOptions = [
    { id: 1, name: "Dobírkou", price: 0 },
];

const CheckoutOptions: React.FC = () => {
    const [checkoutData, setCheckoutData] = useRecoilState(checkoutState);
    const packetaWidgetRef = useRef<PacketaWidgetRef>(null);

    const handleShippingChange = (value: number, handlePoint=false) => {
        const shippingMethod = shippingOptions.find(option => option.id === value);
        if (shippingMethod) {
            setCheckoutData(prevState => ({
                ...prevState,
                shippingPaymentInfo: {
                    ...prevState.shippingPaymentInfo,
                    shippingMethod
                }
            }));

            // Pokud je vybraná volba Zásilkovna
            if (value === 4 && !handlePoint) {
                packetaWidgetRef.current?.handlePickButtonClick();
            } else {
                packetaWidgetRef.current?.resetStates(); // provede reset useState v PacketaWidget
            }
        }
    };

    // Zasilkovna - nastaveni vydejniho mista
    const handleSetShippingPoint = (place: string, placeText: string) => {
        console.log("handleSetShippingPoint", place, placeText)
        setCheckoutData(prevState => ({
            ...prevState,
            shippingPaymentInfo: {
                ...prevState.shippingPaymentInfo,
                shippingMethod: prevState.shippingPaymentInfo.shippingMethod
                    ? {
                        ...prevState.shippingPaymentInfo.shippingMethod,
                        place: place,
                        placeText: placeText,
                    }
                    : null, // nebo jiné chování, pokud ve vyjímečném případě není ShippingMethod definovaný
            },
        }));

        // pokud nekdo klikne na tlacitko, tak provedu nastaveni selectu
        // TODO neprepina se to pokud je na jine metoda a kliknu na vzbrat pobocku
        const shippingMethod = shippingOptions.find(option => option.id === 4);
        if (shippingMethod) {
            setCheckoutData(prevState => ({
                ...prevState,
                shippingPaymentInfo: {
                    ...prevState.shippingPaymentInfo,
                    shippingMethod: {
                        id: shippingMethod.id,
                        name: shippingMethod.name,
                        price: shippingMethod.price,
                        place: place,
                        placeText: placeText,
                    }
                }
            }));
        }
    };

    const handlePaymentChange = (value: number) => {
        const paymentMethod = paymentOptions.find(option => option.id === value);
        if (paymentMethod) {
            setCheckoutData(prevState => ({
                ...prevState,
                shippingPaymentInfo: {
                    ...prevState.shippingPaymentInfo,
                    paymentMethod
                }
            }));
        }
    };

    return (
        <Container>
            <Box mb={4}>
                <Box display="flex" alignItems="center" mb={1}>
                    <LocalShippingIcon color="primary" />
                    <Typography variant="h6" ml={1}>Zvolte způsob dopravy</Typography>
                </Box>
                <RadioGroup value={checkoutData.shippingPaymentInfo.shippingMethod?.id} onChange={(e) => handleShippingChange(Number(e.target.value))}>
                    {shippingOptions.map(option => (
                        <Paper key={option.id} variant="outlined" sx={{ p: 2, mb: 1 }}>
                            <Grid container alignItems="center">
                                <Grid item xs={9}>
                                    <FormControlLabel
                                        value={option.id}
                                        control={<Radio />}
                                        label={option.name}
                                    />
                                    {option.id === 4 && (
                                        <>
                                            <PacketaWidget ref={packetaWidgetRef} onPointSelected={(point, text) => handleSetShippingPoint(point, text)} />
                                            {checkoutData.shippingPaymentInfo.shippingMethod?.placeText}
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            color: option.price === 0 ? 'green' : 'inherit',
                                        }}
                                    >
                                        {option.price === 0 ? 'ZDARMA' : `${option.price} Kč`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </RadioGroup>
            </Box>

            <Box>
                <Box display="flex" alignItems="center" mb={1}>
                    <AccountBalanceWalletIcon color="primary" />
                    <Typography variant="h6" ml={1}>Zvolte způsob platby</Typography>
                </Box>
                <RadioGroup value={checkoutData.shippingPaymentInfo.paymentMethod?.id} onChange={(e) => handlePaymentChange(Number(e.target.value))}>
                    {paymentOptions.map(option => (
                        <Paper key={option.id} variant="outlined" sx={{ p: 2, mb: 1 }}>
                            <Grid container alignItems="center">
                                <Grid item xs={9}>
                                    <FormControlLabel
                                        value={option.id}
                                        control={<Radio />}
                                        label={option.name}
                                    />
                                </Grid>
                                <Grid item xs={3} textAlign="right">
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            color: option.price === 0 ? 'green' : 'inherit',
                                        }}
                                    >
                                        {option.price === 0 ? 'ZDARMA' : `${option.price} Kč`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </RadioGroup>
            </Box>
        </Container>
    );
};

export default CheckoutOptions;