import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import {useNavigate} from "react-router-dom";

function ProductCTA() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    navigate("/contact");
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="section" sx={{ mt: 10, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'primary.main',
              py: 8,
              px: 3,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 400 }}>
              <Typography variant="h2" component="h2" gutterBottom>
                 Máte speciální přání?
              </Typography>
              <Typography variant="h5" sx={{ textAlign: 'justify', mb: 1 }}>
                  Plánujete objednat více obrazů? Nejste si jistí, jaký formát zvolit? Jsme tu pro Vás! Rádi Vám pomůžeme s výběrem i vytvořením dokonalého designu. Neváhejte nás kontaktovat a společně najdeme řešení, které splní vaše představy.
              </Typography>
              {/*<TextField*/}
              {/*  noBorder*/}
              {/*  placeholder="Váš email"*/}
              {/*  variant="standard"*/}
              {/*  sx={{ width: '100%', mt: 3, mb: 2 }}*/}
              {/*/>*/}
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ width: '100%', mt: 3 }}
              >
                Kontaktujte nás
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
              background: 'url(/static/productCTAImageDots.png)',
            }}
          />
            <Box
                component="img"
                src="https://images.unsplash.com/photo-1527853787696-f7be74f2e39a?auto=format&fit=crop&w=750"
                alt="call to action"
                sx={{
                    position: 'absolute',
                    top: -28,
                    left: -28,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    maxWidth: 600,
                    borderRadius: '16px', // Zaoblení rohů
                }}
            />


        </Grid>
      </Grid>
        {/*TODO*/}
      <Snackbar
        open={open}
        closeFunc={handleClose}
        message="We will send you our best offers, once a week."
      />
    </Container>
  );
}

export default ProductCTA;
